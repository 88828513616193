<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          Sessions List
          <v-spacer></v-spacer>
          <v-avatar color="primary">
            <v-icon dark>mdi-calendar-text</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="table.headers" :items="allSessions" :loading="allSessions.length == 0">

            <template v-slot:item.date="{item}">
              {{ item.date | formatDate}}
            </template>
            
            <template v-slot:item.quota="{item}">
              {{ item.quota }} Orang
            </template>


            <template v-slot:item.action="{ item }">
              <v-menu offset-x left>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list nav dense>
                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-list-item @click="editData(item)" v-on="on">
                        <v-icon> mdi-file-edit </v-icon>
                      </v-list-item>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" @click="deleteConfirmation(item)">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-list-item>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip :color="item.status == 'open' ? 'success' : 'error'">
                <span>{{ item.status }}</span>
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="edit.dialog" width="800" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Edit Verify Session </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed @click="edit.dialog = false" color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="edit.data">
          <v-form ref="edit">
            <v-row>
              <v-col>
                <v-menu
                  ref="datepick"
                  v-model="edit.datepick"
                  :close-on-content-click="false"
                  :return-value.sync="edit.data.date"
                  offset-y
                  min-width="auto"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      append-icon="mdi-calendar"
                      v-model="edit.data.date"
                      label="Date"
                    ></v-text-field>
                  </template>

                  <v-date-picker no-title v-model="edit.data.date">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$refs.datepick.save(edit.data.date)">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn icon @click="edit.datepick = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quota"
                  type="number"
                  v-model.number="edit.data.quota"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Status"
                  :items="status_item"
                  item-text="text"
                  item-value="value"
                  v-model="edit.data.status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            large
            color="primary"
            @click="saveData"
            :loading="edit.loading"
            :disabled="edit.loading"
          >
            <span>save</span>
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="del.dialog" width="500" persistent>
      <v-card>
        <v-alert class="mb-0" prominent icon="mdi-alert" color="warning" dark>
          <v-row no-gutters class="align-center">
            Yakin ingin menghapus data ini?
            <v-spacer></v-spacer>
            <v-btn
              text
              :loading="del.loading"
              :disabled="del.loading"
              @click="handleDelete"
            >
              <span>ya</span>
            </v-btn>
            <v-btn class="ml-2" @click="del.dialog = false">
              <span>batal</span>
            </v-btn>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>

    <v-snackbar multi-line v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>

      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      table: {
        headers: [
          { text: "ID", value: "id", align: 'right', sortable: false },
          { text: "Date", value: "date", align: 'center', sortable: false },
          { text: "Quota", value: "quota" },
          { text: "Status", value: "status" },
          { text: "Action", value: "action" },
        ],
      },
      status_item: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
      ],
      edit: {
        datepick: false,
        loading: false,
        dialog: false,
        data: null,
      },
      del: {
        dialog: false,
        loading: false,
        id: null,
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["updateVerifySession", "deleteVerifySession"]),
    editData(item) {
      this.edit.data = item;
      this.edit.dialog = true;
    },
    saveData() {
      this.edit.loading = true;
      let data = this.edit.data;
      let isValid = this.$refs.edit.validate();

      if (isValid) {
        if (this.updateVerifySession(data)) {
          this.edit.loading = false;
          this.showNotification("success");
        } else {
          this.edit.loading = false;
          this.showNotification("error");
        }
      } else {
        this.edit.loading = false;
        this.showNotification("error");
      }
    },
    deleteConfirmation(item) {
      this.del.id = item.id;
      this.del.dialog = true;
    },
    handleDelete() {
      this.del.loading = true;
      let id = this.del.id;

      if (this.deleteVerifySession(id)) {
        this.del.loading = false;
        this.showNotification("success");
        this.del.dialog = false;
      } else {
        this.del.loading = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Data berhasil diupdate";
          break;

        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Data gagal diupdate";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allSessions"]),
  },
  filters: {
    formatDate: (value) => {
      return moment(value).format("dddd, Do MMM YYYY");
    }
  }
};
</script>